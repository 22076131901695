import Cookies from 'js-cookie';
import { safeParse } from '../json/json.ts';
import { ModuleType } from '../types.ts';

export enum StorageKey {
  AuthClient = 'com.seeeverything.client',
  AuthType = 'com.seeeverything.authType',
  Debug = 'com.seeeverything.debug.enabled',
  DebugFilterEnabled = 'com.seeeverything.debug.filter.enabled',
  DebugFilterText = 'com.seeeverything.debug.filter.text',
  Forms = 'com.seeeverything.forms',
  IsAuthenticated = 'auth/isAuthenticated',
  Module = 'com.seeeverything.module',
  Timezone = 'com.seeeverything.tenant.timezone',
  PinSticky = 'com.seeeverything.editor.isPinSticky',
  ReturnPath = 'TEMP/returnPath',
  TenantId = 'com.seeeverything.tenant.tenantId',
  TenantRememberSelection = 'com.seeeverything.tenant.rememberTenant',
}

export type LocalStorageCustomEvent<T = string> = {
  key: string;
  value: T | null;
};

export const CUSTOM_LOCAL_STORAGE_CHANGED = 'CustomLocalStorageChanged';

const sessionStorageApi = {
  get: (key: string) => {
    if (typeof window === 'undefined') return;
    return window.sessionStorage.getItem(key);
  },
  set: (key: string, value: string) => {
    if (typeof window === 'undefined') return;

    if (value === null) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  },
};

const localStorageApi = {
  get: <T>(key: string) => {
    if (typeof window === 'undefined') return;
    const value = window.localStorage.getItem(key);
    return safeParse<{ value: T }>(value)?.value;
  },
  set: <T>(key: string, value: T) => {
    if (typeof window === 'undefined') return;

    window.localStorage.setItem(key, JSON.stringify({ value }));
    window.dispatchEvent(
      new CustomEvent<LocalStorageCustomEvent<T>>(
        CUSTOM_LOCAL_STORAGE_CHANGED,
        { detail: { key, value } },
      ),
    );
  },
  clear: (key: string) => {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem(key);
    window.dispatchEvent(
      new CustomEvent<LocalStorageCustomEvent<null>>(
        CUSTOM_LOCAL_STORAGE_CHANGED,
        { detail: { key, value: null } },
      ),
    );
  },
};

const clearAuthValues = () => {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });

  const excludeKeys: string[] = [
    StorageKey.Debug,
    StorageKey.DebugFilterEnabled,
    StorageKey.DebugFilterText,
    StorageKey.Module,
    StorageKey.PinSticky,
    StorageKey.ReturnPath,
    StorageKey.TenantId,
    StorageKey.TenantRememberSelection,
  ];

  Object.keys(window.localStorage)
    .filter(
      (key) => !excludeKeys.includes(key) && !key.startsWith(StorageKey.Forms),
    )
    .forEach((key) => {
      window.localStorage.removeItem(key);
    });
};

export const storageApi = {
  localStorageApi,
  sessionStorageApi,

  clearAuthValues,

  clearIsAuthenticated: () => localStorageApi.clear(StorageKey.IsAuthenticated),

  clearTenantState: () => {
    localStorageApi.clear(StorageKey.TenantRememberSelection);
  },

  timezone: {
    getLocalStorage: () => localStorageApi.get<string>(StorageKey.Timezone),
    getSessionStorage: () => sessionStorageApi.get(StorageKey.Timezone),
    set: (to: string) => {
      sessionStorageApi.set(StorageKey.Timezone, to);
      return localStorageApi.set(StorageKey.Timezone, to);
    },
  },

  tenant: {
    getLocalStorage: () => localStorageApi.get<string>(StorageKey.TenantId),
    getSessionStorage: () => sessionStorageApi.get(StorageKey.TenantId),
    set: (to: string) => {
      sessionStorageApi.set(StorageKey.TenantId, to);
      return localStorageApi.set(StorageKey.TenantId, to);
    },
  },

  getTenantRememberSelection: () =>
    localStorageApi.get<boolean>(StorageKey.TenantRememberSelection),
  setTenantRememberSelection: (to: boolean) =>
    localStorageApi.set<boolean>(StorageKey.TenantRememberSelection, to),

  module: {
    getLocalStorage: () => localStorageApi.get<ModuleType>(StorageKey.Module),
    getSessionStorage: () =>
      sessionStorageApi.get(StorageKey.Module) as ModuleType,
    set: (to: ModuleType) => {
      sessionStorageApi.set(StorageKey.Module, to);
      return localStorageApi.set(StorageKey.Module, to);
    },
  },

  returnPath: {
    set: (to: string) => localStorageApi.set<string>(StorageKey.ReturnPath, to),
    readAndClear: (defaultIfEmpty: string) => {
      const result =
        localStorageApi.get<string>(StorageKey.ReturnPath) || defaultIfEmpty;
      localStorageApi.clear(StorageKey.ReturnPath);
      return result;
    },
  },
};
