/**
 * Stringify and replace any unsafe characters with a safe variant.
 */
export const stringify = (input?: any) => {
  if (!input) {
    return undefined;
  }

  return JSON.stringify(input)
    .replace(/–/g, '-') /* Replace `–` with '-' */
    .replace(/‘/g, "'") /* Replace `‘` with `'` */
    .replace(/’/g, "'") /* Replace `’` with `'` */
    .replace(/“/g, '\\"') /* Replace `“` with '\"' */
    .replace(/”/g, '\\"') /* Replace `”` with '\"' */
    .replace(/&nbsp;/g, ''); /* Replace `&nbsp;` with `` */
};

/*
 * String escape a JS string
 */
export const stringEscape = (input?: string) => {
  if (!input) {
    return undefined;
  }

  return input
    .replace(/\\/g, '\\\\') /* Replace `\` with `\\` */
    .replace(/\//g, '\\/') /* Replace `/` with `\/` */
    .replace(/"/g, '\\"') /* Replace `"` with `\"` */
    .replace(/'/g, "\\'"); /* Replace `'` with `\'` */
};

/**
 * Unescape and optionally JSON.parse an escaped JSON string.
 */
export const unescape = (input?: any, parse = true) => {
  if (!input) {
    return undefined;
  }

  try {
    const unescaped = input
      .replace(/\\"/g, '"')
      .replace(/\\'/g, "'")
      .replace(/\\\\/g, '\\');

    return parse ? JSON.parse(unescaped) : unescaped;
  } catch {
    // For now - just ignore this but consider logging this if problematic.
    return;
  }
};

export const safeParse = <T = any>(input?: string): T => {
  if (!input) return;

  try {
    return JSON.parse(input);
  } catch {
    return;
  }
};
