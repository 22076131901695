/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress.js';
import { makeStyles } from '@material-ui/core';
import { css } from '@emotion/react';

export interface ISpinnerProps {
  color?: string;
  size?: number;
  center?: boolean;
}

/**
 * Wrapper for material CircularProgress. See:
 * - [CircularProgress API](https://material-ui.com/api/circular-progress/)
 * - [Demos](https://material-ui.com/components/progress/)
 */
const Spinner = ({ size = 32, color, center }: ISpinnerProps) => {
  const { colorPrimary } = makeStyles({ colorPrimary: { color } })();

  return (
    <div css={center ? styles.center : undefined}>
      <CircularProgress size={size} classes={{ colorPrimary }} />
    </div>
  );
};

const styles = {
  center: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

export default memo(Spinner);
