import gql from 'graphql-tag';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';

export interface IAuthorizedTenants {
  [tenantId: string]: {
    personId: string;
  };
}

/**
 * Parses and returns the tenants from the provided auth info.
 */
export async function tenants(
  client: IGraphQLClient,
): Promise<IAuthorizedTenants> {
  type GraphQLTenantResponse = {
    authorization: {
      tenants: { nodes: Array<{ tenant: string; personId: string }> };
    };
  };
  const response = await client.query<GraphQLTenantResponse>({
    fetchPolicy: 'cache-first',
    query: GqlQueryTenants,
  });

  const nodes = response?.data.authorization.tenants.nodes;
  if (!nodes) return {};

  return nodes.reduce(
    (acc, { tenant, personId }) => ({
      ...acc,
      [tenant]: { personId: personId },
    }),
    {},
  );
}

const GqlQueryTenants = gql`
  query AuthTenants {
    authorization {
      tenants {
        nodes {
          tenant
          personId
        }
      }
    }
  }
`;
